import {Component, Input, OnInit} from '@angular/core';
import {Child} from '../../models/Child';
import {ModalController} from '@ionic/angular';
import {UtilityService} from '../../services/utility.service';
import {Group} from '../../models/Group';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {

  @Input()
  public isAdd: boolean = false;

  @Input()
  public group: Group;

  constructor(
    public modalController: ModalController,
    public utilityService: UtilityService,
  ) { }

  ngOnInit() {
    if(this.group == undefined){
      this.group = new Child();
    }
  }

  public save(): void{
    if(this.childIsValid()){
      this.modalController.dismiss({
        'child': this.group,
        'isAdd': this.isAdd
      });
    }else{
      this.utilityService.showToast('MODALS.ADD_CHILD.MANDATORY_MISSING', UtilityService.TOAST_STATUS.warning);
    }
  }

  private stringIsValid(s: string): boolean{
    return s != undefined && s.length > 0;
  }

  public childIsValid(): boolean{
    return this.stringIsValid(this.group.name);
  }

  public close(): void{
    this.modalController.dismiss();
  }

}
