import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {TemplateService} from "../../services/template.service";
import {Template} from '../../models/Template';
import {TemplateColor} from '../../models/TemplateColor';

@Component({
    selector: 'app-mobile-design-select',
    templateUrl: './mobile-design-select.component.html',
    styleUrls: ['./mobile-design-select.component.scss'],
})
export class MobileDesignSelectComponent implements OnInit {

    public templates: {template: Template, colors: {color: TemplateColor, image: any}[]}[] = [];
    @Input()
    public selectedTemplateId: string;
    @Input()
    public selectedColor: string = '';
    @Input()
    public mode: string = 'folder';

    constructor(
        private templateService: TemplateService,
        public modalController: ModalController
    ) {
    }

    ngOnInit() {
      if (this.mode == 'folder'){
        if(this.templateService.templatesFolder.length < 1){
            this.templateService.queryTemplate();
        }
        this.templates = this.templateService.templatesFolder;
      }else{
        if(this.templateService.templatesPages.length < 1){
          this.templateService.queryTemplate();
        }
        this.templates = this.templateService.templatesPages;
      }
      this.templateService.templateUpdateEvent.subscribe(() => {
        if (this.mode == 'folder') {
          this.templates = this.templateService.templatesFolder;
        }else{
          this.templates = this.templateService.templatesPages;
        }
      });
    }

    public selectedTemplateChange(event: any): void{
        this.selectedTemplateId = event.target.value;
        console.log(this.selectedTemplateId);
    }

    public close(colorId: string): void{
        this.modalController.dismiss({
            selectedColor: colorId,
        });
    }

    public abort(): void{
        this.modalController.dismiss(undefined);
    }

    public getSelectedTemplate(): {template: Template, colors: {color: TemplateColor, image: any}[]}{
        if(this.templates != undefined && this.templates.find(x => x.template.id == this.selectedTemplateId) != undefined){
            return this.templates.find(x => x.template.id == this.selectedTemplateId);
        }
        return {template: undefined, colors: []};
    }

}
