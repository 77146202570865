import { Injectable } from '@angular/core';
import {BasicService} from './basic.service';
import {Child} from '../models/Child';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {UtilityService} from './utility.service';
import * as Routes from './Routes';
import {Observable} from 'rxjs';
import {GenericResponse} from '../models/GenericResponse';

@Injectable({
  providedIn: 'root'
})
export class ChildService extends BasicService<Child>{

  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {
    super(dataService, http, Routes.CHILD_ROUTE);
  }

  public getAllByDaycareId(daycareId: string): Observable<GenericResponse<Child[]>> {
    return this.http.get<GenericResponse<Child[]>>(`${Routes.CHILD_ROUTE}/all/all/${daycareId}/`, this.dataService.getHttpOptions());
  }

  public getAllByGroup(daycareId: string, groupId: string): Observable<GenericResponse<Child[]>>{
    return this.http.get<GenericResponse<Child[]>>(`${Routes.CHILD_ROUTE}/all/all/${daycareId}/byGroup/${groupId}/`, this.dataService.getHttpOptions());
  }

}
