import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-custom-template-category',
  templateUrl: './custom-template-category.component.html',
  styleUrls: ['./custom-template-category.component.scss'],
})
export class CustomTemplateCategoryComponent implements OnInit {

  @Input()
  public categories: string[] = [];

  public selectedTab: number = 1;
  public newCategory: string = '';
  public selectedCategory: string = '';
  constructor(
    private modalController: ModalController,
    ) { }

  ngOnInit() {}


  public close(): void{
      this.modalController.dismiss({close: true});
  }

  public save(): void {
    this.modalController.dismiss({category: this.selectedTab == 1 ? this.newCategory : this.selectedCategory});
  }

  public inputValid(): boolean {
    if(this.selectedTab == 1){
      if(this.newCategory == undefined || this.newCategory.length < 1 || this.categories.indexOf(this.newCategory) > -1){
        return false;
      }
    }else{
      if(this.selectedCategory == undefined || this.selectedCategory.length < 1){
        return false;
      }
    }
    return true;
  }
}
