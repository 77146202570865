import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "./guards/authGuard.service";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'group-child-list',
    loadChildren: () => import('./pages/group-child-list/group-child-list.module').then( m => m.GroupChildListPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'folder-design-select',
    loadChildren: () => import('./pages/folder-design-select/folder-design-select.module').then( m => m.FolderDesignSelectPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'page-design-select',
    loadChildren: () => import('./pages/page-design-select/page-design-select.module').then( m => m.PageDesignSelectPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'folder-editor',
    loadChildren: () => import('./pages/folder-editor/folder-editor.module').then( m => m.FolderEditorPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'page-editor',
    loadChildren: () => import('./pages/page-editor/page-editor.module').then( m => m.PageEditorPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'digital-folder',
    loadChildren: () => import('./pages/digital-folder/digital-folder.module').then( m => m.DigitalFolderPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'folder-list',
    loadChildren: () => import('./pages/folder-list/folder-list.module').then( m => m.FolderListPageModule)
  },
  {
    path: 'order-overview',
    loadChildren: () => import('./pages/order-overview/order-overview.module').then( m => m.OrderOverviewPageModule)
  },
  {
    path: 'register-activation/:registrationToken',
    loadChildren: () => import('./pages/register-activation/register-activation.module').then( m => m.RegisterActivationPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'forgot-password-confirm/:passwordResetToken',
    loadChildren: () => import('./pages/forgot-password-confirm/forgot-password-confirm.module').then( m => m.ForgotPasswordConfirmPageModule)
  },  {
    path: 'new-editor',
    loadChildren: () => import('./pages/new-editor/new-editor.module').then( m => m.NewEditorPageModule)
  },
  {
    path: 'custom-templates',
    loadChildren: () => import('./pages/custom-templates/custom-templates.module').then( m => m.CustomTemplatesPageModule)
  },









];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
