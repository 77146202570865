import {EventEmitter, Injectable} from '@angular/core';
import {BasicService} from './basic.service';
import {Daycare} from '../models/Daycare';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';
import * as Routes from './Routes';
import {GenericResponse} from '../models/GenericResponse';
import {UserService} from './user.service';
import {UtilityService} from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class DaycareService extends BasicService<Daycare>{

  private checkDaycareInterval;
  public selfDaycare : Daycare = undefined;
  private hasPermission: boolean = true;
  public daycareLoadedEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private userService: UserService,
    private utilityService: UtilityService,
  ) {
    super(dataService, http, Routes.DAYCARE_ROUTE);

    if(this.userService.self != undefined){

      // this.daycareQuery();
      this.get(this.userService.self.daycareId).toPromise()
        .catch(err => {
          if(err.status == 403){
            this.hasPermission = false;
          }
          this.utilityService.logError(err);
        })
        .then((res: GenericResponse<Daycare>) => {
          if(res != undefined && res.body != undefined){
            this.selfDaycare = res.body;
            this.daycareLoadedEvent.next();
          }
        });

    }else{

      this.userService.userLoadEvent.subscribe(() => {

        if(this.selfDaycare == undefined){
          // this.daycareQuery();
          this.get(this.userService.self.daycareId).toPromise()
            .catch(err => {
              if(err.status == 403){
                this.hasPermission = false;
              }
              this.utilityService.logError(err);
            })
            .then((res: GenericResponse<Daycare>) => {
              if(res != undefined && res.body != undefined){
                this.selfDaycare = res.body;
                this.daycareLoadedEvent.next();
              }
            });
        }

      });

    }

  }

  public daycareQuery(): void{
    let that = this;
    this.checkDaycareInterval = setInterval(() => {
      if(that.hasPermission){
        let token = that.dataService.getClearToken();
        if(token != undefined && token.length > 0 && that.userService.self != undefined && that.userService.self.daycareId != undefined){
          that.get(that.userService.self.daycareId).toPromise()
            .catch(err => {
              if(err.status == 403){
                that.hasPermission = false;
              }
              that.utilityService.logError(err);
            })
            .then((res: GenericResponse<Daycare>) => {
              if(res != undefined && res.body != undefined){
                that.selfDaycare = res.body;
                that.daycareLoadedEvent.next();
              }
            });
        }
      }else{
        clearInterval(this.checkDaycareInterval);
      }
    }, 15000);
  }

  public reloadDaycare(): void {
    if(this.userService.self?.daycareId != undefined){
      this.doReload();
    }else{
      let subscr = this.userService.userLoadEvent.subscribe(() => {
        this.doReload();
        subscr.unsubscribe();
      });
    }
  }

  private doReload(): void {

    this.get(this.userService.self.daycareId).toPromise()
      .catch(err => {
        if(err.status == 403){
          this.hasPermission = false;
        }
        this.utilityService.logError(err);
      })
      .then((res: GenericResponse<Daycare>) => {
        if(res != undefined && res.body != undefined){
          this.selfDaycare = res.body;
          this.daycareLoadedEvent.next();
        }
      });
  }
}
