import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-page-name-input',
  templateUrl: './page-name-input.component.html',
  styleUrls: ['./page-name-input.component.scss'],
})
export class PageNameInputComponent implements OnInit {

  @Input()
  public projectName1: string = '';
  @Input()
  public projectName2: string = '';
  @Input()
  public canClose: boolean = false;
  @Input()
  public templateMode: boolean = false;

  constructor(
    private modalController: ModalController,
    ) { }

  ngOnInit() {}

  public save(): void{
    if(this.projectName1 == undefined || this.projectName1 == ''){
      this.projectName1 = 'unnamed';
    }
    if(this.projectName2 == undefined || this.projectName2 == ''){
      this.projectName2 = 'unnamed';
    }
    this.modalController.dismiss({projectName1: this.projectName1, projectName2: this.projectName2});
  }

  public close(): void{
    if(this.canClose){
      this.modalController.dismiss({close: true});
    }
  }

}
