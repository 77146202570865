import { Injectable } from '@angular/core';
import {BasicService} from './basic.service';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';
import * as Routes from './Routes';
import {Group} from '../models/Group';
import {Observable} from 'rxjs';
import {GenericResponse} from '../models/GenericResponse';
import {Child} from '../models/Child';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends BasicService<Group>{

  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {
    super(dataService, http, Routes.GROUP_ROUTE);
  }

  public getAllByDaycareId(daycareId: string): Observable<GenericResponse<Group[]>> {
    return this.http.get<GenericResponse<Group[]>>(`${Routes.GROUP_ROUTE}/all/all/${daycareId}/`, this.dataService.getHttpOptions());
  }
}
