import * as Routes from "./Routes";
import {EventEmitter, Injectable} from '@angular/core';
import {Template} from '../models/Template';
import {TemplateColor} from '../models/TemplateColor';
import {DataService} from './data.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {AuthGuardService} from '../guards/authGuard.service';
import {UtilityService} from './utility.service';
import {Observable} from 'rxjs';
import {GenericResponse} from '../models/GenericResponse';
import {TemplateArea} from '../models/TemplateArea';
import {BasicService} from './basic.service';
import {FileService} from './file.service';
import {ProjectService} from './project.service';
import {Project} from '../models/Project';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends BasicService<Template>{

  public static AREA_ORIENTATION_TYPES:{key: string, name:string}[] = [{key: 'horizontal', name: 'Horizontal'}, {key: 'vertical', name: 'Vertical'}];
  public static AREA_TYPES:{key: string, name:string}[] = [{key: 'image', name: 'Image'}, {key: 'text', name: 'Text'}];
  public static TEMPLATE_TYPES:{key: string, name: string}[] = [{key: 'template.type.page', name: 'Seite'},{key: 'template.type.binder', name: 'Ordner'}];

  public templatesFolder: {template: Template, colors: {color: TemplateColor, image: any}[]}[];
  public templatesPages: {template: Template, colors: {color: TemplateColor, image: any}[]}[];
  public projectTemplates: {project: Project, image: any}[];
  public projectTemplateCategories: string[] = [];
  public templateUpdateEvent: EventEmitter<any> = new EventEmitter<any>();

  private lastLoaded: Date;

  constructor(
    private dataService: DataService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private authGuard: AuthGuardService,
    private utilityService: UtilityService,
    private fileService: FileService,
    private projectService: ProjectService,
  ) {
    super(dataService, http, Routes.TEMPLATE_ROUTE);
    this.templatesFolder = [];
    this.templatesPages = [];
  }

  public getAllTemplatesByType(templateType: string): Observable<GenericResponse<Template[]>>{
    return this.http.get<GenericResponse<Template[]>>(`${Routes.TEMPLATE_ROUTE}/byType/${templateType}/`, this.dataService.getHttpOptions());
  }

  public async getAllTemplatesByCategory(templateCategory: string): Promise<Template[]>{
    let response = [];
    await this.http.get<GenericResponse<Template[]>>(`${Routes.TEMPLATE_ROUTE}/byCategory/${templateCategory}/`, this.dataService.getHttpOptions()).toPromise()
      .catch(err => {
        console.log(err);
      })
      .then((res: GenericResponse<Template[]>) => {
        if(res?.status == 200 && Array.isArray(res?.body)){
          response = res.body;
        }
      });
    return response;
  }

  public async getCategories(): Promise<string[]> {
    let response = [];
    await this.http.get<GenericResponse<string[]>>(`${Routes.TEMPLATE_ROUTE}/category/all/`, this.dataService.getHttpOptions()).toPromise()
      .catch(err => {
        // tslint:disable-next-line:no-console
        console.log(err);
      })
      .then((res: GenericResponse<string[]>) => {
        if(res?.status == 200 && Array.isArray(res?.body)){
          response = res.body;
          if(response.indexOf(null) > -1){
            response.splice(response.indexOf(null), 1);
          }
          if(response.indexOf(undefined) > -1){
            response.splice(response.indexOf(undefined), 1);
          }
          if(response.indexOf('') > -1){
            response.splice(response.indexOf(''), 1);
          }
        }
      });
    return response;
  }

  // Template Areas:

  public getAllTemplateAreas(templateId: string): Observable<GenericResponse<TemplateArea[]>>{
    return this.http.get<GenericResponse<TemplateArea[]>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/area/all/all/`, this.dataService.getHttpOptions());
  }

  public createTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<TemplateArea>>{
    return this.http.post<GenericResponse<TemplateArea>>(`${Routes.TEMPLATE_ROUTE}/${templateArea.templateId}/areas`,
      templateArea, this.dataService.getHttpOptions());
  }

  public updateTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<TemplateArea>>{
    return this.http.put<GenericResponse<TemplateArea>>(`${Routes.TEMPLATE_ROUTE}/${templateArea.templateId}/area/${templateArea.id}/`,
      templateArea, this.dataService.getHttpOptions());
  }

  public deleteTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<string>>{
    return this.http.delete<GenericResponse<string>>(`${Routes.TEMPLATE_ROUTE}/${templateArea.templateId}/area/${templateArea.id}/`,
      this.dataService.getHttpOptions());
  }

  // Template Colors:

  public getAllTemplateColors(templateId: string): Observable<GenericResponse<TemplateColor[]>>{
    return this.http.get<GenericResponse<TemplateColor[]>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/color/all/all/`, this.dataService.getHttpOptions());
  }

  public async getAllTemplateColorsByTemplateId(templateId: string): Promise<TemplateColor[]> {
    let response = undefined;
    await this.http.get<GenericResponse<TemplateColor[]>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/color/all/all/`, this.dataService.getHttpOptions()).toPromise()
      .catch(err => console.log(err))
      .then((res: GenericResponse<TemplateColor[]>) => {
        if(res?.status == 200 && Array.isArray(res?.body)){
          response = this.loadImagesForColors(res.body);
        }
      });
    return response;
  }

  private async loadImagesForColors(colors: TemplateColor[]): Promise<TemplateColor[]> {
    for (let col of colors) {
      await this.fileService.getFile(col.thumbnailFileId).toPromise().catch(err => {
        this.utilityService.logError(err);
      }).then(value1 => {
        if (value1?.status == 200 && value1?.body != undefined) {
          let objectUrl = URL.createObjectURL(value1.body);
          col.image = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
        }
      });
    }
    return colors;
  }

  public getTemplateColor(templateId: string, templateColorId: string): Observable<GenericResponse<TemplateColor>>{
    return this.http.get<GenericResponse<TemplateColor>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/color/${templateColorId}/`, this.dataService.getHttpOptions());
  }

  public async getTemplateColorById(templateId: string, templateColorId: string): Promise<TemplateColor> {
    let color: TemplateColor = undefined;
    await this.http.get<GenericResponse<TemplateColor>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/color/${templateColorId}/`, this.dataService.getHttpOptions()).toPromise().catch(err => {
      this.utilityService.logError(err);
    }).then((res: GenericResponse<TemplateColor>) => {
      if(res?.status == 200 && res?.body != undefined){
        color = res.body;
      }
    });
    return color;
  }

  public async getColorByImageId(colorImageId: string): Promise<TemplateColor> {
    let color: TemplateColor = undefined;
    await this.http.get<GenericResponse<TemplateColor>>(`${Routes.TEMPLATE_ROUTE}/colorByImageId/${colorImageId}/`, this.dataService.getHttpOptions()).toPromise().catch(err => {
      this.utilityService.logError(err);
    }).then((res: GenericResponse<TemplateColor>) => {
      if(res?.status == 200 && res?.body != undefined){
        color = res.body;
      }
    });
    return color;
  }

  public createTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<TemplateColor>>{
    return this.http.post<GenericResponse<TemplateColor>>(`${Routes.TEMPLATE_ROUTE}/${templateColor.templateId}/color/`,
      templateColor, this.dataService.getHttpOptions());
  }

  public updateTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<TemplateColor>>{
    return this.http.put<GenericResponse<TemplateColor>>(`${Routes.TEMPLATE_ROUTE}/${templateColor.templateId}/color/${templateColor.id}/`,
      templateColor, this.dataService.getHttpOptions());
  }

  public deleteTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<string>>{
    return this.http.delete<GenericResponse<string>>(`${Routes.TEMPLATE_ROUTE}/${templateColor.templateId}/color/${templateColor.id}/`,
      this.dataService.getHttpOptions());
  }

  public async queryTemplate(showError: boolean = true): Promise<void> {
    if (this.templatesFolder == undefined || this.templatesFolder.length < 1 || this.lastLoaded == undefined ||
      this.lastLoaded.getTime() > (new Date().getTime() + (5 * 60 * 1000))) {
      this.lastLoaded = new Date();
      await this.loadTemplates(0);
      this.templateUpdateEvent.next();
        /*
        .then(async () => {
        await this.loadColors(0, showError);
      });
         */
    }
  }

  private async loadTemplates(tries: number): Promise<void>{
    let retry = false;
    let error = false;
    tries += 1;
    for(let type of TemplateService.TEMPLATE_TYPES){
      await this.getAllTemplatesByType(type.key).toPromise().catch(err => {
        this.utilityService.logError(err);
        retry = true;
        error = true;
      }).then((value: GenericResponse<Template[]>) => {
        let tmp = [];
        value.body.forEach(t => {
          tmp.push({template: t,colors: []});
        });
        if(type.key == 'template.type.page'){
          this.templatesPages = tmp.sort((a, b) => a.template.name.localeCompare(b.template.name));
        }else{
          this.templatesFolder = tmp.sort((a, b) => a.template.name.localeCompare(b.template.name));
        }
      });
    }
    if(retry && tries <= 5){
      await this.loadTemplates(tries);
    }else{
      if(error){
        this.utilityService.showToast('SERVICES.TEMPLATE.LOADING_TEMPLATES_FAILED', UtilityService.TOAST_STATUS.danger);
      }
    }
  }

  private async loadColors(tries: number, showError: boolean = true): Promise<void> {
    let retry = false;
    let colorError = false;
    let fileError = false;
    tries += 1;
    for(let type of TemplateService.TEMPLATE_TYPES) {
      let arr;
      if(type.key == 'template.type.page') {
        arr = this.templatesPages;
      }else{
        arr = this.templatesFolder;
      }
      for (let i = 0; i < arr.length; i++) {
        await this.getAllTemplateColors(arr[i].template.id).toPromise().catch(err => {
          colorError = true;
          this.utilityService.logError(err);
          retry = true;
        }).then(async (value: GenericResponse<TemplateColor[]>) => {
          if (value.body.length > 0) {
            let tmp = [];
            await value.body.forEach(col => {
              this.fileService.getFile(col.thumbnailFileId).toPromise().catch(err => {
                fileError = true;
                this.utilityService.logError(err);
                retry = true;
              }).then(value1 => {
                if(value1 != undefined && value1.status != undefined && value1.status == 200 && value1.body != undefined){
                  let objectUrl = URL.createObjectURL(value1.body);
                  tmp.push({color: col, image: this.sanitizer.bypassSecurityTrustUrl(objectUrl)});
                }
              });
            });
            arr[i].colors = tmp;
            if(type.key == 'template.type.page') {
              this.templatesPages = arr;
            }else{
              this.templatesFolder = arr;
            }
            this.templateUpdateEvent.next();
          }
        });
      }
      if(type.key == 'template.type.page') {
        this.templatesPages = arr;
      }else{
        this.templatesFolder = arr;
      }
    }
    if(retry && tries <= 5){
      this.loadColors(tries, showError);
    }else{
      if(colorError){
        this.utilityService.showToast('SERVICES.TEMPLATE.LOADING_TEMPLATE_COLORS_FAILED', UtilityService.TOAST_STATUS.danger);
      }
      if(fileError && showError){
        this.utilityService.showToast('SERVICES.TEMPLATE.LOADING_TEMPLATE_COLOR_FILE_FAILED', UtilityService.TOAST_STATUS.danger);
      }
    }
  }

  public async loadProjectTemplates(): Promise<void> {
    let projects: Project[] = [];

    await this.projectService.getAllTemplatePages().toPromise()
      .catch(err => console.log(err))
      .then((res: GenericResponse<Project[]>) => {
        if (res?.status == 200 && Array.isArray(res?.body)) {
          projects = res.body;
        }
      });

    let tmp: { project: Project, image: any }[] = [];

    for (let p of projects) {
      tmp.push({project: p, image: undefined});
      /*
      await this.fileService.getFile(p.screenshotFileId).toPromise().catch(err => {
        this.utilityService.logError(err);
      }).then(value1 => {
        if (value1 != undefined && value1.status != undefined && value1.status == 200 && value1.body != undefined) {
          let objectUrl = URL.createObjectURL(value1.body);
          tmp.push({project: p, image: this.sanitizer.bypassSecurityTrustUrl(objectUrl)});
        }
      });
       */
    }

    this.projectTemplates = tmp;
    this.loadProjectTemplateCategories();
  }

  public loadProjectTemplateCategories(): void {
    let cats = [];
    for(let t of this.projectTemplates){
      if(cats.indexOf(t.project.customTemplateCategory) < 0 && t.project.customTemplateCategory != undefined && t.project.customTemplateCategory.length > 0){
        cats.push(t.project.customTemplateCategory);
      }
    }
    this.projectTemplateCategories = cats;
  }

  public async loadProjectTemplateImages(project: Project): Promise<SafeUrl> {
    let objectUrl: SafeUrl = undefined;
    let p = this.projectTemplates.find(x => x.project.id == project.id);
    if(p != undefined && p.image == undefined || p.image.length < 1){
      await this.fileService.getFile(project.screenshotFileId).toPromise().catch(err => {
        this.utilityService.logError(err);
      }).then(value1 => {
        if (value1 != undefined && value1.status != undefined && value1.status == 200 && value1.body != undefined) {
          objectUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(value1.body));
          p.image = objectUrl;
        }
      });
    }else {
      objectUrl = p.image;
    }
    return objectUrl;
  }

}
