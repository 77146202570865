import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UtilityService} from '../../services/utility.service';
import {Child} from '../../models/Child';
import {Router} from '@angular/router';
import {ProjectService} from '../../services/project.service';
import {Group} from '../../models/Group';
import {SettingsService} from '../../services/settings.service';
import {GenericResponse} from '../../models/GenericResponse';
import {Setting} from '../../models/Setting';
import {TemplateService} from '../../services/template.service';

@Component({
  selector: 'app-child-selection',
  templateUrl: './child-selection.component.html',
  styleUrls: ['./child-selection.component.scss'],
})
export class ChildSelectionComponent implements OnInit {

  @Input()
  public children: Child[] = [];

  @Input()
  public byGroups: boolean = false;

  @Input()
  public groups: Group[] = [];
  @Input()
  public isCopy: boolean = false;

  public selectedChildren: Child[] = [];
  public groupChildModel: {group: Group, expanded: boolean, children: Child[]}[] = [];
  public defaultTemplate: string;

  constructor(
    public modalController: ModalController,
    public utilityService: UtilityService,
    private router: Router,
    private projectService: ProjectService,
    private settingService: SettingsService,
    private templateService: TemplateService
    ) { }

  ngOnInit() {
    this.loadSetting();
    if(this.byGroups){
      this.groupChildModel = [];
      for(let g of this.groups){
        let children = this.children.filter(x => x.groupId == g.id);
        this.groupChildModel.push({group: g, expanded: true, children });
      }
    }
  }


  private loadSetting(): void {
    this.settingService.get(SettingsService.EDITOR_CUSTOM_TEMPLATE_DEFAULT_TEMPLATE).toPromise()
      .then((res: GenericResponse<Setting>) => {
        if(res?.status == 200 && res?.body != undefined && res?.body?.value?.length > 0){
          this.defaultTemplate = res.body.value;
        }
      });
  }
  public close(): void{
    this.modalController.dismiss();
  }

  public childSelection(child: Child): void{
    if(this.selectedChildren.indexOf(child) > -1){
      this.selectedChildren.splice(this.selectedChildren.indexOf(child), 1);
    }else{
      this.selectedChildren.push(child);
    }
  }

  public childIsSelected(child): boolean{
    return this.selectedChildren.indexOf(child) > -1;
  }

  public selectAll(): void{
    for(let c of this.children){
      if(!this.childIsSelected(c)){
        this.childSelection(c);
      }
    }
  }

  public deselectAll(): void{
    this.selectedChildren = [];
  }

  public async submit(): Promise<void>{
    if(this.isCopy){
      this.copyToChildren();
    }else{
      this.createMultiple();
    }
  }

  public copyToChildren(): void{
    this.modalController.dismiss({selectedChildren: this.selectedChildren});
  }

  public async createMultiple(): Promise<void>{
    let ids: { childId: string, pageNumber: number }[] = [];
    let selectedGroups: string[] = [];
    for(let c of this.selectedChildren){
      ids.push({childId: c.id, pageNumber: undefined});
      if(selectedGroups.find(x => x == c.groupId) == undefined){
        selectedGroups.push(c.groupId);
      }
    }


    for(let i = 0; i < selectedGroups.length; i++){
      await this.projectService.getPageCount(selectedGroups[i]).toPromise()
        .catch(err => {
          this.utilityService.logError(err);
        })
        .then((res) => {
          if(res != undefined && res.body != undefined && res.status == 200){
            let tmpIds = JSON.parse(res.body) as { childId: string, pageNumber: number }[];
            for(let i of tmpIds){
              for(let c of ids){
                if(i.childId == c.childId){
                  c.pageNumber = i.pageNumber;
                }
              }
            }
          }
        });
    }

    for(let id of ids){
      id.pageNumber += 1;
    }


    localStorage.setItem('multipleChildren', 'true')
    localStorage.setItem('nextPageNumbers', JSON.stringify(ids));

    if(this.defaultTemplate != undefined && this.defaultTemplate.length > 0){
      let colors = await this.templateService.getAllTemplateColorsByTemplateId(this.defaultTemplate);
      if(colors?.length > 0){
        let color = colors[0];
        if(color != undefined){
          localStorage.setItem('imageId',color.showingFileId);
          localStorage.setItem('mobileImageId',color.mobileShowingFileId);
          localStorage.setItem('templateId',color.templateId);
          this.router.navigate(['new-editor']);
        }else{
          this.router.navigate(['/page-design-select']);
        }
      }else{
        this.router.navigate(['/page-design-select']);
      }
    }else{
      this.router.navigate(['/page-design-select']);
    }

    this.close();
  }

}
