import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-tablet-editor-menu',
    templateUrl: './tablet-editor-menu.component.html',
    styleUrls: ['./tablet-editor-menu.component.scss'],
})
export class TabletEditorMenuComponent implements OnInit {

    constructor(
        public modalController: ModalController
    ) {}

    // tslint:disable-next-line:no-empty
    ngOnInit() {
    }

    public close(action: string = ''): void{
        this.modalController.dismiss({
            'action': action,
        });
    }

}
