import {environment} from '../../environments/environment';

const API_URL = environment.backendURL;

export const CHILD_ROUTE = `${API_URL}/child`;
export const GROUP_ROUTE = `${API_URL}/group`;
export const PROJECT_ROUTE = `${API_URL}/project`;
export const USER_ROUTE = `${API_URL}/user`;
export const TEMPLATE_ROUTE = `${API_URL}/template`;
export const SETTINGS_ROUTE = `${API_URL}/settings`;
export const DAYCARE_ROUTE = `${API_URL}/daycare`;
export const INSTITUTION_ROUTE = `${API_URL}/institution`;
export const FILE_ROUTE = `${API_URL}/file`;
export const PURCHASE_ROUTE = `${API_URL}/purchase`;
export const PRODUCT_ROUTE = `${API_URL}/product`;
export const CLIPART_ROUTE = `${API_URL}/clipart`;

