import { Injectable } from '@angular/core';
import {DataService} from './data.service';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {AuthGuardService} from '../guards/authGuard.service';
import {UtilityService} from './utility.service';
import {Observable} from 'rxjs';
import * as Routes from './Routes';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private dataService: DataService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private authGuard: AuthGuardService,
    private utilityService: UtilityService
  ) { }

  public upload(file): Observable<{ id: string }>{
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<{ id: string }>(`${Routes.FILE_ROUTE}/`, formData, this.dataService.getHttpOptions());
  }

  public uploadBlob(blob: Blob): Observable<{ id: string }>{
    const formData = new FormData();
    formData.append('file', blob, `${(new Date()).getTime()}-screenshot.jpg`);
    return this.http.post<{ id: string }>(`${Routes.FILE_ROUTE}/`, formData, this.dataService.getHttpOptions());
  }

  public getFile(fileId: string): Observable<any>{
    return this.http.get<any>(`${Routes.FILE_ROUTE}/${fileId}/`, this.dataService.getHttpOptionsDownload());
  }
}
