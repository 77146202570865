import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {GenericResponse} from "../models/GenericResponse";
import * as Routes from "./Routes";
import {User} from "../models/User";
import {DataService} from "./data.service";
import {HttpClient} from "@angular/common/http";
import {BasicService} from './basic.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BasicService<User>{

  private checkUserInterval;
  public self : User = undefined;
  public userUpdateEvent: EventEmitter<any> = new EventEmitter<any>();
  public userLoadEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private dataService: DataService,
      private http: HttpClient,
      ) {
    super(dataService, http, Routes.USER_ROUTE);
    // this.userQuery();
    this.loadSelf();
    this.userUpdateEvent.subscribe(() => {
      this.loadSelf();
    });
  }

/*
  public create(user: User): Observable<GenericResponse<User>> {
    return this.http.post<GenericResponse<User>>(`${Routes.USER_ROUTE}/`, user, this.dataService.getHttpOptions());
  }
  public update(user: User): Observable<GenericResponse<User>> {
    return this.http.put<GenericResponse<User>>(`${Routes.USER_ROUTE}/${user.id}/`, user, this.dataService.getHttpOptions());
  }
  public delete(userId: string): Observable<GenericResponse<string>> {
    return this.http.delete<GenericResponse<string>>(`${Routes.USER_ROUTE}/${userId}/`, this.dataService.getHttpOptions());
  }
  public get(userId: string): Observable<GenericResponse<User>> {
    return this.http.get<GenericResponse<User>>(`${Routes.USER_ROUTE}/${userId}/`, this.dataService.getHttpOptions());
  }
  public getAll(): Observable<GenericResponse<User[]>> {
    return this.http.get<GenericResponse<User[]>>(`${Routes.USER_ROUTE}/all/all/`, this.dataService.getHttpOptions());
  }
 */
  public getSelf(): Observable<GenericResponse<User>>{
    return this.http.get<GenericResponse<User>>(`${Routes.USER_ROUTE}/`, this.dataService.getHttpOptions());
  }
  public cancelSubscription(): Observable<GenericResponse<any>>{
    return this.http.post<GenericResponse<any>>(`${Routes.USER_ROUTE}/subscription/end/`,{}, this.dataService.getHttpOptions());
  }
  public loadSelf(): void{
    let token = this.dataService.getClearToken();
    if(token != undefined && token.length > 0){
      this.getSelf().toPromise()
        .catch(err => {
          if(err.status == 401){
            this.dataService.clearToken();
          }
        })
        .then((res: GenericResponse<User>) => {
          if(res != undefined && res.body != undefined){
            this.self = res.body;
            this.userLoadEvent.next();
          }
        });
    }
  }

  public userQuery(): void{
    let that = this;
    this.checkUserInterval = setInterval(() => {
      that.loadSelf();
    }, 15000);
  }
}
