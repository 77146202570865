import { Injectable } from '@angular/core';
import {BasicService} from './basic.service';
import {Project} from '../models/Project';
import {HttpClient} from '@angular/common/http';
import {DataService} from './data.service';
import * as Routes from './Routes';
import {Observable} from 'rxjs';
import {GenericResponse} from '../models/GenericResponse';
import {TemplateArea} from '../models/TemplateArea';
import {ProjectItem} from '../models/ProjectItem';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BasicService<Project>{

  public static PROJECT_TYPES = {
    PROJECT_TYPE_PAGE: {
      key: "project.type.page",
      name: "Seite",
    },
    PROJECT_TYPE_BINDER: {
      key: "project.type.binder",
      name: "Ordner",
    },
    PROJECT_TYPE_TEMPLATE: {
      key: "project.type.template",
      name: "Template",
    }
  };

  public static PROJECT_STATES = {
    PROJECT_STATE_WORK_IN_PROGRESS: {
      key: "project.state.in.progress",
      name: "FOLDER_STATES.PROJECT_STATE_WORK_IN_PROGRESS"
    },
    PROJECT_STATE_READY_TO_PRINT: {
      key: "project.state.ready.to.print",
      name: "FOLDER_STATES.PROJECT_STATE_READY_TO_PRINT"
    },
    PROJECT_STATE_ORDERED: {
      key: "project.state.ordered",
      name: "FOLDER_STATES.PROJECT_STATE_ORDERED"
    },
    PROJECT_STATE_DISPATCHED: {
      key: "project.state.dispatched",
      name: "FOLDER_STATES.PROJECT_STATE_DISPATCHED"
    },
    PROJECT_STATE_FINAL: {
      key: "project.state.final",
      name: "FOLDER_STATES.PROJECT_STATE_FINAL"
    }
  };

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {
    super(dataService, http, Routes.PROJECT_ROUTE);
  }

  public getAllPagesByChild(daycareId: string, childId: string): Observable<GenericResponse<Project[]>>{
    return this.http.get<GenericResponse<Project[]>>(`${Routes.PROJECT_ROUTE}/all/${daycareId}/${childId}/${ProjectService.PROJECT_TYPES.PROJECT_TYPE_PAGE.key}/`, this.dataService.getHttpOptions());
  }
  public getAllFoldersByChild(daycareId: string, childId: string): Observable<GenericResponse<Project[]>>{
    return this.http.get<GenericResponse<Project[]>>(`${Routes.PROJECT_ROUTE}/all/${daycareId}/${childId}/${ProjectService.PROJECT_TYPES.PROJECT_TYPE_BINDER.key}/`, this.dataService.getHttpOptions());
  }

  public getAllTemplatePages(): Observable<GenericResponse<Project[]>>{
    return this.http.get<GenericResponse<Project[]>>(`${Routes.PROJECT_ROUTE}/allByType/${ProjectService.PROJECT_TYPES.PROJECT_TYPE_TEMPLATE.key}/`, this.dataService.getHttpOptions());
  }


  public getProjectItem(itemId: string, projectId: string): Observable<GenericResponse<ProjectItem>>{
    return this.http.get<GenericResponse<ProjectItem>>(`${Routes.PROJECT_ROUTE}/${projectId}/item/${itemId}/`, this.dataService.getHttpOptions());
  }

  public getAllProjectItems(projectId: string): Observable<GenericResponse<ProjectItem[]>>{
    return this.http.get<GenericResponse<ProjectItem[]>>(`${Routes.PROJECT_ROUTE}/${projectId}/item/all/all/`, this.dataService.getHttpOptions());
  }

  public createProjectItem(item: ProjectItem): Observable<GenericResponse<ProjectItem>>{
    return this.http.post<GenericResponse<ProjectItem>>(`${Routes.PROJECT_ROUTE}/${item.projectId}/item/`,
      item, this.dataService.getHttpOptions());
  }

  public updateProjectItem(item: ProjectItem): Observable<GenericResponse<ProjectItem>>{
    return this.http.put<GenericResponse<ProjectItem>>(`${Routes.PROJECT_ROUTE}/${item.projectId}/item/${item.id}/`,
      item, this.dataService.getHttpOptions());
  }

  public deleteProjectItem(item: ProjectItem): Observable<GenericResponse<string>>{
    return this.http.delete<GenericResponse<string>>(`${Routes.PROJECT_ROUTE}/${item.projectId}/item/${item.id}/`,
      this.dataService.getHttpOptions());
  }

  public getCurrentMemoryUsage(daycareId: string): Observable<GenericResponse<{ total: string, usage: string }>>{
    return this.http.get<GenericResponse<{ total: string, usage: string }>>(`${Routes.PROJECT_ROUTE}/memory-usage/${daycareId}/calculate/`,
      this.dataService.getHttpOptions());
  }

  public printSingleProject(projectId: string, type: string): Observable<any>{
    return this.http.post(`${Routes.PROJECT_ROUTE}/preprint/`, {type, projectsToPrint: [projectId], useSelfPrintTemplate: true}, this.dataService.getHttpOptionsDownload());
  }

  public printMultipleProjects(projectIds: string[], type: string): Observable<any>{
    return this.http.post(`${Routes.PROJECT_ROUTE}/preprint/`, {type, projectsToPrint: projectIds, useSelfPrintTemplate: true}, this.dataService.getHttpOptionsDownload());
  }

  public getPageCount(groupId: string): Observable<any>{
    return this.http.get<any>(`${Routes.PROJECT_ROUTE}/page-count/${groupId}/`,
      this.dataService.getHttpOptions());
  }

  public updateBatch(projects: Project[]): Observable<GenericResponse<Project[]>>{
    return this.http.put<GenericResponse<Project[]>>(`${Routes.PROJECT_ROUTE}/update/batch/`,
      projects, this.dataService.getHttpOptions());
  }

  public copyToChildren(projectId: string, childIds: string[]): Observable<GenericResponse<string>>{
    return this.http.post<GenericResponse<string>>(`${Routes.PROJECT_ROUTE}/${projectId}/copyToChildren/`, childIds, this.dataService.getHttpOptions());
  }

  public async getCurrentSiteQuotaUsage(daycareId: string, year: string | number): Promise<{year: string | number, total: number, current: number}>{
    let response = undefined;
    await this.http.get<GenericResponse<{year: string | number, total: number, current: number}>>(`${Routes.PROJECT_ROUTE}/site-quota/${daycareId}/${year}/calculate`, this.dataService.getHttpOptions()).toPromise()
      .then((res: GenericResponse<{year: string | number, total: number, current: number}>) => {
        if(res?.status == 200 && res?.body != undefined){
          response = res.body;
        }
      });
    return response;
  }
}
