import * as ROUTES from "./Routes";
import {EventEmitter, Injectable} from '@angular/core';
import {UtilityService} from './utility.service';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';
import {BasicService} from './basic.service';
import {Setting} from '../models/Setting';
import {GenericResponse} from '../models/GenericResponse';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BasicService<Setting>{

  public static CAPTCHA_KEY = "captcha.key";
  public static SETTING_EDITOR_DEBUG_MODE = "editor.debug_mode";
  public static SETTING_EDITOR_HELP_URL = "editor.helpurl";
  public static MAINTENANCE_KEY = 'maintenance.mode';
  public static PAGE_FREE_SHIP_AMOUNT = 'page.free_ship.amount';
  public static EDITOR_TEXTBOX_BREAK_AT_LETTER = 'editor.textbox.break_at_letter';
  public static EDITOR_CUSTOM_TEMPLATE_DEFAULT_TEMPLATE = 'editor.custom_template.default_template';
  public recaptchaKey: string = '';
  public captchaKeyEvent: EventEmitter<any> = new EventEmitter<any>();
  public maintenanceEvent: EventEmitter<any> = new EventEmitter<any>();
  public maintenanceMode: boolean = false;
  public pageFreeShipAmount: number = 200;
  public breakAtLetter: boolean = false;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private utilityService: UtilityService
  ) {
    super(dataService, http, ROUTES.SETTINGS_ROUTE);
    this.loadCaptcha();
    this.loadMaintenance();
    this.loadPageFreeShipAmount();
    this.loadEditorBreakAtLetter();
    setInterval(() => {
      this.loadMaintenance();
      this.loadPageFreeShipAmount();
      this.loadEditorBreakAtLetter();
    }, 60000);
  }

  public get(key: string): Observable<GenericResponse<Setting>>{
    return this.http.get<GenericResponse<Setting>>(`${ROUTES.SETTINGS_ROUTE}/byKey/${key}/`, this.dataService.getHttpOptions())
  }

  public loadPageFreeShipAmount(): void{

    if(this.dataService.hasToken()){

      this.get(SettingsService.PAGE_FREE_SHIP_AMOUNT).toPromise()
        .catch(err => {
          this.utilityService.logError(err);
        })
        .then((res: GenericResponse<Setting>) => {
          if(res != undefined && res.status != undefined && res.body != undefined && res.status == 200){
            this.pageFreeShipAmount = +res.body.value;
          }
        });
    }
  }

  public loadEditorBreakAtLetter(): void{

    if(this.dataService.hasToken()) {
      this.get(SettingsService.EDITOR_TEXTBOX_BREAK_AT_LETTER).toPromise()
        .catch(err => {
          this.utilityService.logError(err);
        })
        .then((res: GenericResponse<Setting>) => {
          if (res != undefined && res.status != undefined && res.body != undefined && res.body.value != undefined && res.status == 200) {
            this.breakAtLetter = res.body.value == 'true';
          }
        });
    }
  }
  public loadCaptcha(): void{

    this.get(SettingsService.CAPTCHA_KEY).toPromise()
      .catch(err => {
        this.utilityService.logError(err);
        this.utilityService.showToast('PAGES.REGISTER.LOADING_CAPTCHA_KEY_FAILED', UtilityService.TOAST_STATUS.warning);
      })
      .then((res: GenericResponse<Setting>) => {
        if(res != undefined && res.status != undefined && res.body != undefined && res.status == 200) {
          this.recaptchaKey = res.body.value;
          this.captchaKeyEvent.next();
        }
      });
  }

  public loadMaintenance(): void{
    this.get(SettingsService.MAINTENANCE_KEY).subscribe({
      error: err => {
        this.utilityService.logError(err);
        this.maintenanceMode = false;
      },
      next: (response: GenericResponse<Setting>) => {
        if (response.status === 200) {
          this.maintenanceMode = response.body.value == 'true';
        } else {
          this.maintenanceMode = false;
        }
        this.maintenanceEvent.next();
      },
    });
  }
}
