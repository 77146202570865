import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ColorPickerModule} from "ngx-color-picker";
import {CommonModule} from "@angular/common";
import { NgModule } from '@angular/core';
import {NgxFileDropModule} from "ngx-file-drop";
import {TranslateModule} from "@ngx-translate/core";
import {DeleteProfileConfirmComponent} from './delete-profile-confirm/delete-profile-confirm.component';
import {ChildComponent} from './child/child.component';
import {GroupComponent} from './group/group.component';
import {MobileDesignSelectComponent} from './mobile-design-select/mobile-design-select.component';
import {TabletEditorMenuComponent} from './tablet-editor-menu/tablet-editor-menu.component';
import {ChildSelectionComponent} from './child-selection/child-selection.component';
import {IonicModule} from '@ionic/angular';
import {PageDownloadSelectionComponent} from './page-download-selection/page-download-selection.component';
import {PageNameInputComponent} from './page-name-input/page-name-input.component';
import {ConsentComponentComponent} from './consent-component/consent-component.component';
import {TemplateCategorySelectionComponent} from './template-category-selection/template-category-selection.component';
import {CustomTemplateCategoryComponent} from './custom-template-category/custom-template-category.component';

@NgModule({
    declarations: [
      ConsentComponentComponent,
      DeleteProfileConfirmComponent,
      ChildComponent,
      GroupComponent,
      MobileDesignSelectComponent,
      TabletEditorMenuComponent,
      ChildSelectionComponent,
      PageDownloadSelectionComponent,
      PageNameInputComponent,
      TemplateCategorySelectionComponent,
      CustomTemplateCategoryComponent,
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxFileDropModule,
    ColorPickerModule,
    IonicModule,
  ],
})
export class SharedModule {}
