import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private tokenObs: BehaviorSubject<string> = new BehaviorSubject('');
  private token: string;

  constructor() {
    this.setToken(localStorage.getItem('token'));
    this.tokenObs.subscribe(token => {
      this.token = token;
    });
  }

  public getHttpOptions(): object {
    if (this.token === '' || this.token === undefined || this.token === null || this.token === 'null') {
      return {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Access-Control-Allow-Origin': '*',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Auth-Token': '',
        },
      };
    } else {
      return {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Access-Control-Allow-Origin': '*',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Auth-Token': this.token,
        },
      };
    }

  }
  public getHttpOptionsDownload(): object {
    if (this.token === '' || this.token === undefined || this.token === null || this.token === 'null') {
      return {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Access-Control-Allow-Origin': '*',
        },
        responseType: 'blob',
        observe: 'response',
      };
    } else {
      return {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Access-Control-Allow-Origin': '*',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Auth-Token': this.token,
        },
        responseType: 'blob',
        observe: 'response',
      };
    }

  }

  public setToken(token: string): void {
    localStorage.setItem('token', token);
    this.tokenObs.next(token);
    this.token = token;
  }

  public clearToken(): void {
    localStorage.removeItem('token');
    this.tokenObs.next(undefined);
    this.token = undefined;
  }

  public getTokenObs(): Observable<string> {
    return this.tokenObs;
  }

  public getClearToken(): string {
    return this.token;
  }

  public hasToken(): boolean {
    return this.token != undefined && this.token.length > 0 && this.token != 'undefined' && this.token != 'null';
  }

}
