import {EventEmitter, Injectable} from '@angular/core';
import {BasicService} from './basic.service';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';
import * as Routes from './Routes';
import {GenericResponse} from '../models/GenericResponse';
import {Institution} from '../models/Institution';
import {DaycareService} from './daycare.service';
import {UtilityService} from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService extends BasicService<Institution>{

  private checkInstitutionInterval;
  public selfInstitution : Institution = undefined;
  private hasPermission: boolean = true;
  public institutionLoadedEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private daycareService: DaycareService,
    private utilityService: UtilityService,
  ) {
    super(dataService, http, Routes.INSTITUTION_ROUTE);
    /*
    this.institutionQuery();
    this.daycareService.daycareLoadedEvent.subscribe(() => {
      if(this.selfInstitution == undefined){
        this.get(this.daycareService.selfDaycare.institutionId).toPromise()
          .catch(err => {
            if(err.status == 403){
              this.hasPermission = false;
            }
            this.utilityService.logError(err);
          })
          .then((res: GenericResponse<Institution>) => {
            if(res != undefined && res.body != undefined){
              this.selfInstitution = res.body;
              this.institutionLoadedEvent.next();
            }
          });
      }
    });
     */
  }

  public institutionQuery(): void{
    let that = this;
    this.checkInstitutionInterval = setInterval(() => {
      if(that.hasPermission){
        let token = that.dataService.getClearToken();
        if(token != undefined && token.length > 0 && that.daycareService.selfDaycare != undefined && that.daycareService.selfDaycare.institutionId != undefined){
          that.get(that.daycareService.selfDaycare.institutionId).toPromise()
            .catch(err => {
              if(err.status == 403){
                that.hasPermission = false;
              }
              that.utilityService.logError(err);
            })
            .then((res: GenericResponse<Institution>) => {
              if(res != undefined && res.body != undefined){
                that.selfInstitution = res.body;
                that.institutionLoadedEvent.next();
              }
            });
        }
      }else{
        clearInterval(this.checkInstitutionInterval);
      }
    }, 15000);
  }
}
