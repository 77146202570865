import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  public spinnerChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public spinnerProgressUpdate: EventEmitter<number> = new EventEmitter<number>();
  public spinnerText: string = '';
  public hasProgress: boolean = false;
  public maxProgress: number = 1;

  constructor() { }

  public open(spinnerText: string = '', hasProgress: boolean = false, maxProgress: number = 1): void{
    this.spinnerText = spinnerText;
    this.hasProgress = hasProgress;
    this.maxProgress = maxProgress;
    this.spinnerChangeEvent.next(true);
  }

  public close(): void{
    this.spinnerText = '';
    this.hasProgress = false;
    this.maxProgress = 1;
    this.spinnerChangeEvent.next(false);
  }

  public progressUpdate(): void{
    this.spinnerProgressUpdate.next(1);
  }

}
