import * as Routes from './Routes';
import {EventEmitter, Injectable} from '@angular/core';
import {DataService} from './data.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {UtilityService} from './utility.service';
import {Observable} from 'rxjs';
import {GenericResponse} from '../models/GenericResponse';
import {Registration} from "../models/Registration";
import {DaycareService} from './daycare.service';
import {UserService} from './user.service';
import {InstitutionService} from './institution.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedInEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private dataService: DataService,
      private http: HttpClient,
      private router: Router,
      private utilityService: UtilityService,
      private daycareService: DaycareService,
      private userService: UserService,
      private institutionService: InstitutionService) { }


  public login(email: string, password: string): Observable<GenericResponse<string>> {
    return this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/login/`, {email, password}, this.dataService.getHttpOptions());
  }

  public logout(): void {
    this.dataService.clearToken();
    this.daycareService.selfDaycare = undefined;
    this.userService.self = undefined;
    this.institutionService.selfInstitution = undefined;
    this.router.navigate(['/login']);
  }

  public checkTokenValidation(): Observable<GenericResponse<string>> {
    return this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/token/validate/`, {}, this.dataService.getHttpOptions());
  }

  public register(user: Registration): Observable<GenericResponse<string>> {
    return this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/registration/`, user, this.dataService.getHttpOptions());
  }

  public activateViaToken(token: string): Observable<GenericResponse<string>>{
    return this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/activate/${token}/`, {}, this.dataService.getHttpOptions());
  }

  public resetPassword(email: string): Observable<GenericResponse<string>> {
    return this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/resetPassword/`, {email}, this.dataService.getHttpOptions());
  }

  public resetPasswordConfirm(password: string, token: string): Observable<GenericResponse<string>> {
    return this.http.post<GenericResponse<string>>(
        `${Routes.USER_ROUTE}/resetPassword/confirm/`,
        {passwordResetToken: token, newPassword:password},
        this.dataService.getHttpOptions()
    );
  }

  public async refreshToken(): Promise<boolean>{
    let success = false;
    await this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/refreshToken`, {}, this.dataService.getHttpOptions()).toPromise()
        .catch(() => {
          this.utilityService.showToast('TOASTS.TOKEN_REFRESH_FAILED', UtilityService.TOAST_STATUS.warning);
        })
        .then((res: GenericResponse<string>) => {
          if(res.token != undefined && res.token.length > 0){
            this.dataService.setToken(res.token);
            success = true;
          }
        });
    return success;
  }
}
