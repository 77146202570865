import {Component, Input, OnInit} from '@angular/core';
import {TemplateService} from '../../services/template.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-template-category-selection',
  templateUrl: './template-category-selection.component.html',
  styleUrls: ['./template-category-selection.component.scss'],
})
export class TemplateCategorySelectionComponent implements OnInit {
  @Input() public hasAllOption: boolean = false;
  @Input() public hasCustomOption: boolean = false;

  public loading: boolean = false;
  public categories: string[] = [];
  public displayCategories: string[] = [];
  public filterContent: string = '';

  constructor(
    private modalController: ModalController,
    private templateService: TemplateService,
  ) { }

  ngOnInit() {
    this.categories = [];
  }

  async ionViewWillEnter(): Promise<void> {
    this.loading = true;
    await this.loadCategories();
    this.doFilter();
    this.loading = false;
  }

  private async loadCategories(): Promise<void> {
    this.categories = await this.templateService.getCategories();
  }

  public close(): void{
    this.modalController.dismiss();
  }

  public doFilter(): void {
    this.displayCategories = this.categories.filter(x => x.toLowerCase().includes(this.filterContent.toLowerCase()));
  }

  public selectItem(item, specialType: string = undefined): void {
    this.modalController.dismiss({item, specialType});
  }

}
