export class Child{
  public id: string;
  public daycareId: string;
  public groupId: string;
  public name: string;
  public birthdate: string = '';
  public startYear: string = '';
  public endYear: string = '';
  public createdAt: any;
  public updatedAt: any;
}
