import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {UtilityService} from '../services/utility.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor{

  constructor(
    private utils: UtilityService,
    private authService: AuthService
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(catchError(error => {
      if (error.status === 400) {
        // Show message
        this.utils.showToast("TOASTS.TOKEN_VALIDATION_UNKNOWN_ERROR", UtilityService.TOAST_STATUS.warning, 20000);
      }

      // Invalid token error
      else if (error.status === 401) {
        // do TokenRefresh https://stackoverflow.com/questions/45202208/angular-4-interceptor-retry-requests-after-token-refresh
      }

      // Access denied error
      else if (error.status === 403) {
        // Show message
      }

      // Server error
      else if (error.status === 500) {
        // Show message
        this.utils.showToast("TOASTS.TOKEN_VALIDATION_UNKNOWN_ERROR", UtilityService.TOAST_STATUS.warning, 20000);
      }

      // Maintenance error
      else if (error.status === 503) {
        // Show message
        // Redirect to the maintenance page
      }

      return throwError(error);
    }));
  }
}
