import {Observable} from 'rxjs';
import {GenericResponse} from '../models/GenericResponse';
import {DataService} from './data.service';
import {HttpClient} from '@angular/common/http';

export class BasicService<T> {

  public routeBase : string = '';

  private data: DataService;
  private h: HttpClient;

  constructor(dataService: DataService, http: HttpClient, route: string) {
    this.data = dataService;
    this.h = http;
    this.routeBase = route;
  }



  public create(obj: T): Observable<GenericResponse<T>> {
    return this.h.post<GenericResponse<T>>(`${this.routeBase}/`, obj, this.data.getHttpOptions());
  }
  public update(obj: T): Observable<GenericResponse<T>> {
    let id = undefined;
    if(obj.hasOwnProperty('id')){
      // @ts-ignore
      id = obj.id;
    }
    return this.h.put<GenericResponse<T>>(`${this.routeBase}/${id}/`, obj, this.data.getHttpOptions());
  }
  public delete(id: string): Observable<GenericResponse<string>> {
    return this.h.delete<GenericResponse<string>>(`${this.routeBase}/${id}/`, this.data.getHttpOptions());
  }
  public get(id: string): Observable<GenericResponse<T>> {
    return this.h.get<GenericResponse<T>>(`${this.routeBase}/${id}/`, this.data.getHttpOptions());
  }
  public getAll(): Observable<GenericResponse<T[]>> {
    return this.h.get<GenericResponse<T[]>>(`${this.routeBase}/all/all/`, this.data.getHttpOptions());
  }
}
