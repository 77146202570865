import {CanActivate, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {DataService} from "../services/data.service";
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GenericResponse} from "../models/GenericResponse";
import {SettingsService} from '../services/settings.service';
import {UtilityService} from '../services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  private session;
  private tokenCheckIntervalInMs = 15000;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private settingsService: SettingsService,
    private utils: UtilityService
  ) {
  }

  public canActivate(): Observable<boolean> {

    if (this.settingsService.maintenanceMode) {
      return new Observable<boolean>((observer) => {
        observer.next(false);
        observer.complete();
      });
    }


    const token = this.dataService.getClearToken();
    if (token === undefined || token === null || token === '') {
      this.authService.logout();
      this.stopLoginSession();
      return new Observable<boolean>((observer) => {
        observer.next(false);
        observer.complete();
      });
    }

    return new Observable<boolean>((observer) => {
      this.authService.checkTokenValidation().subscribe({
        error: (err: HttpErrorResponse) => {
          // tslint:disable-next-line:no-console
          console.log(err);
          if(err.status == 401){
            this.authService.logout();
            this.stopLoginSession();
          }
          observer.next(false);
        },
        next: (response: GenericResponse<string>) => {
          if (response.status === 200) {
            this.dataService.setToken(response.token);
            this.authService.loggedInEvent.next();
            observer.next(true);
          } else if(response.status == 401){
            this.authService.logout();
            this.stopLoginSession();
            observer.next(false);
          }else{
            this.utils.showToast("TOASTS.TOKEN_VALIDATION_UNKNOWN_ERROR", UtilityService.TOAST_STATUS.warning, 20000);
            observer.next(false);
          }
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }

  public startLoginSession(): void {
    // this.checkToken();
  }

  public stopLoginSession(): void {
    if (this.session != undefined) {
      clearInterval(this.session);
    }
  }

  private checkToken(): void {
    this.session = setInterval(async () => {
      this.canActivate().subscribe();
    }, this.tokenCheckIntervalInMs);
  }
}
