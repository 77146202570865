import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-consent-component',
  templateUrl: './consent-component.component.html',
  styleUrls: ['./consent-component.component.scss'],
})
export class ConsentComponentComponent implements OnInit {
  @Input() public title: string = '';
  @Input() public bodyRows: string[] = [];
  @Input() public checkmarks: string[] = [];
  @Input() public inputs: {title: string, type: string, returnVal: string}[] = [];
  @Input() public overrideButtons: {title: string, type: string}[] = [];

  public checkmarkMap: {content: string, checked: boolean}[] = [];
  public inputMap: {title: string, type: string, property: string, val: any}[] = [];

  constructor(
    public modalController: ModalController
  ) {
  }

  ngOnInit() {
    this.checkmarkMap = [];
    this.inputMap = [];
    for(let mark of this.checkmarks){
      this.checkmarkMap.push({content: mark, checked: false});
    }
    for(let i of this.inputs){
      this.inputMap.push({title: i.title, type: i.type, property: i.returnVal, val: undefined});
    }
    console.log(this.inputs, this.inputMap);
  }

  public isValid(): boolean{
    for(let c of this.checkmarkMap){
      if(!c.checked) return false;
    }
    return true;
  }

  public submit(): void{
    if(!this.isValid()) return;
    let data = {submit: true};
    for(let i of this.inputMap){
      data[i.property] = i.val;
    }
    this.modalController.dismiss(data);
  }

  public buttonAction(button: {title: string, type: string}){
    if(button.type == 'submit'){
      if(!this.isValid()) return;
      let data = {submit: true};
      for(let i of this.inputMap){
        data[i.property] = i.val;
      }
      this.modalController.dismiss(data);

    }else{
      this.modalController.dismiss();
    }
  }

  public close(): void{
    this.modalController.dismiss();
  }

}
