import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {Project} from '../../models/Project';
import {Child} from '../../models/Child';
import {ChildService} from '../../services/child.service';
import {ProjectService} from '../../services/project.service';
import {UtilityService} from '../../services/utility.service';
import {GenericResponse} from '../../models/GenericResponse';
import {TranslateService} from '@ngx-translate/core';
import {SpinnerService} from '../../services/spinner.service';
import {GroupService} from '../../services/group.service';
import {Group} from '../../models/Group';

@Component({
  selector: 'app-page-download-selection',
  templateUrl: './page-download-selection.component.html',
  styleUrls: ['./page-download-selection.component.scss'],
})
export class PageDownloadSelectionComponent implements OnInit {

  @Input()
  public children: Child[] = [];
  @Input()
  public isDelete: boolean = false;
  @Input()
  public isEdit: boolean = false;

  @Input()
  public preselectedIds: string[] = [];

  public selectedMonth: string;

  public pages: {child: Child, groupId: string, pages: Project[], expanded: boolean}[] = [];
  public displayPages: {child: Child, groupId: string, pages: Project[], expanded: boolean}[] = [];
  public selectedIds: string[] = [];
  public filterContent: string;
  private childLoadCounter = 0;
  public loading = false;
  public progressCalc: number = 0;

  private childLoadEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private modalController: ModalController,
    private childService: ChildService,
    private projectService: ProjectService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    private alertController: AlertController,
    private spinnerService: SpinnerService,
    private groupService: GroupService,
  ) { }

  ngOnInit() {
  }

  async ionViewWillEnter(): Promise<void>{
    if(this.children == undefined || this.children.length < 1){
      this.utilityService.showToast('MODALS.DOWNLOAD_FOR_MULTIPLE.CHILDREN_EMPTY', UtilityService.TOAST_STATUS.danger);
      this.close();
      return;
    }

    this.selectedIds = [];
    this.pages = [];
    this.displayPages = [];
    this.selectedMonth = `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`;

    this.childLoadCounter = 0;
    this.childLoadEvent.subscribe(() => {
      if(this.childLoadCounter >= this.children.length){
        this.childLoadCounter = 0;
        this.loading = false;
        this.pages = this.pages.sort((a,b) => a.child.name.localeCompare(b.child.name));
        this.displayPages = this.displayPages.sort((a,b) => a.child.name.localeCompare(b.child.name));
      }
    });
    this.loading = true;
    for(let c of this.children){
      this.loadPagesForChild(c);
    }
    if(this.preselectedIds != undefined && this.preselectedIds.length > 0){
      this.selectedIds = this.preselectedIds;
    }
    if(this.preselectedIds != undefined && this.preselectedIds.length > 0){
      this.selectedIds = this.preselectedIds;
    }
  }

  private async loadPagesForChild(child: Child): Promise<void> {
    this.projectService.getAllPagesByChild(child.daycareId, child.id).toPromise()
      .catch(err => {
        this.utilityService.logError(err);
      })
      .then((res: GenericResponse<Project[]>) => {
        if (res != undefined && res.status != undefined && res.status == 200) {
          let pages = res.body;
          if(this.isEdit || this.isDelete){
            pages = pages.filter(x => x.currentState == 'project.state.in.progress');
          }
          this.pages.push({child: child, groupId: child.groupId, pages: pages, expanded: false});
          this.displayPages.push({child: child, groupId: child.groupId, pages: pages, expanded: false});
        }
        this.childLoadCounter++;
        this.progressCalc = (this.childLoadCounter / this.children.length) * 100;
        this.childLoadEvent.next();
      });

  }

  public getPagesForChild(childId: string): {page1: Project, page2: Project}[]{
    let pages = this.getUnmappedPagesForChild(childId);
    let toReturn: {page1: Project, page2: Project}[] = [];

    for(let p of pages.filter(x => x?.pagePosition != undefined && x?.pagePosition % 2 != 0)){
      toReturn.push({page1: p, page2: pages.find(x => x?.pagePosition == p?.pagePosition + 1)});
    }

    return toReturn;
  }

  private getUnmappedPagesForChild(childId: string): Project[]{
    if ((this.filterContent == undefined || this.filterContent.length < 1) && !this.isEdit){
      return this.displayPages.find(x => x.child.id == childId).pages.filter(x => new Date(x.tstamp).getFullYear() == new Date(this.selectedMonth).getFullYear() && new Date(x.tstamp).getMonth() == new Date(this.selectedMonth).getMonth());
    }else{
      return this.displayPages.find(x => x.child.id == childId).pages;
    }
  }

  public selectAll(): void{
    for(let c of this.displayPages){
      for(let p of this.getUnmappedPagesForChild(c.child.id)){
        if(this.selectedIds.indexOf(p.id) < 1){
          this.selectedIds.push(p.id);
        }
      }
    }
  }

  public deselectAll(): void{
    this.selectedIds = [];
  }

  public isSelected(pageObj: {page1: Project, page2: Project}): boolean{
    if(pageObj.page1 != undefined){
      return this.selectedIds.indexOf(pageObj.page1.id) > -1;
    }
    if(pageObj.page2 != undefined){
      return this.selectedIds.indexOf(pageObj.page2.id) > -1;
    }
    return false;
  }

  public pageSelection(pageObj: {page1: Project, page2: Project}): void{
    if(pageObj.page1 != undefined){
      if(this.selectedIds.indexOf(pageObj.page1.id) > -1){
        this.selectedIds.splice(this.selectedIds.indexOf(pageObj.page1.id), 1);
      }else{
        this.selectedIds.push(pageObj.page1.id);
      }
    }
    if(pageObj.page2 != undefined){
      if(this.selectedIds.indexOf(pageObj.page2.id) > -1){
        this.selectedIds.splice(this.selectedIds.indexOf(pageObj.page2.id), 1);
      }else{
        this.selectedIds.push(pageObj.page2.id);
      }
    }
  }

  public close(): void{
    this.modalController.dismiss();
  }

  public monthSelection(event): void{
    this.selectedIds = [];
    this.doFilter();
  }

  public async download(): Promise<void> {
    if(this.isDelete) return;
    if (this.selectedIds != undefined && this.selectedIds.length > 0) {
      let loadingText = 'collecting data';
      await this.translate.get('MODALS.SPINNER.COLLECTING_DATA').toPromise()
        .then(res => {
          loadingText = res;
        })
      this.spinnerService.open(loadingText);
      await this.projectService.printMultipleProjects(this.selectedIds, ProjectService.PROJECT_TYPES.PROJECT_TYPE_PAGE.key).toPromise()
        .catch(err => {
          this.spinnerService.close();
          this.utilityService.logError(err);
          this.utilityService.showToast('TOASTS.FILE_CREATION_FAILED', UtilityService.TOAST_STATUS.danger);
        })
        .then(async res => {
          this.spinnerService.close();
          if (res != undefined && res.body != undefined && res.status != undefined && res.status == 200) {
            let blob = new Blob([res.body], {type: 'application/octet-stream'});
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('type', 'hidden');
            link.href = url;
            let date = new Date();
            let groupId = undefined;
            if(this.pages.find(x => x.groupId != undefined) != undefined){
              groupId = this.pages.find(x => x.groupId != undefined).groupId;
            }
            let foundChildren: Child[] = [];
            for(let p of this.pages){
              for(let id of this.selectedIds){
                if(p.pages.find(x => x.id == id) != undefined){
                  if(foundChildren.find(x => x.id == p.child.id) == undefined){
                    foundChildren.push(p.child);
                  }
                }
              }
            }
            let groupName = 'unnamedGroup';
            if (groupId != undefined) {
              await this.groupService.get(groupId).toPromise()
                .catch(err => {
                  console.log(err);
                })
                .then((res: GenericResponse<Group>) => {
                  if (res != undefined && res.status != undefined && res.body != undefined && res.status == 200) {
                    groupName = res.body.name;
                  }
                });
            }
            let day = date.getDate() > 9 ? date.getDate() + '' : '0' + date.getDate();
            let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) + '' : '0' + (date.getMonth() + 1);
            let year = date.getFullYear();
            let hours = date.getHours() > 9 ? date.getHours() + '' : '0' + date.getHours();
            let minutes = date.getMinutes() > 9 ? date.getMinutes() + '' : '0' + date.getMinutes();
            let fileName = `${groupName}-${day}-${month}-${year}-${hours}-${minutes}.pdf`; //res.headers.get('correct-filename');
            if(foundChildren.length == 1 && foundChildren[0] != undefined && foundChildren[0].name != undefined){
              fileName = `${foundChildren[0].name}-${fileName}`;
            }
            link.download = fileName;
              document.body.appendChild(link);
            link.click();
            link.remove();
          }
        });
      this.close();
    }
  }

  public async delete(): Promise<void> {
    if (!this.isDelete) return;
    if (this.selectedIds != undefined && this.selectedIds.length > 0) {
      let message = 'Delete this page?';
      let yes = 'yes';
      let no = 'no';
      await this.translate.get('MODALS.DOWNLOAD_FOR_MULTIPLE.CONFIRM_DELETE').toPromise()
        .then((res: string) => {
          message = res;
        });
      await this.translate.get('BUTTON.YES').toPromise()
        .then((res: string) => {
          yes = res;
        });
      await this.translate.get('BUTTON.NO').toPromise()
        .then((res: string) => {
          no = res;
        });
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: '',
        message: message,
        buttons: [
          {
            text: yes,
            cssClass: 'yes',
            handler: async () => {
              for(let id of this.selectedIds){
                await this.projectService.delete(id).toPromise()
                  .catch(err => {
                    this.utilityService.logError(err);
                  });
              }
              this.modalController.dismiss({reload: true});
            },
          },
          {
            text: no,
            cssClass: 'no',
            handler: () => {
              alert.dismiss();
            },
          },
        ],
      });

      await alert.present();
    }

  }

  public doFilter(): void {
    let result: {child: Child, groupId: string, pages: Project[], expanded: boolean}[] = [];
    if(this.filterContent == '' || this.filterContent == undefined){
      for(let p of this.pages){
        let expanded = this.displayPages.find(x => x.child.id == p.child.id)?.expanded;
        result.push({child: p.child, groupId: p.groupId, pages: p.pages, expanded: expanded != undefined ? expanded : false});
      }
      this.displayPages = result;
      return;
    }

    for(let p of this.pages){
      let pagesSet: {page1: Project, page2: Project}[] = [];

      for(let page of p.pages){
        if(page.pagePosition % 2 == 1){
          let page2 = p.pages.find(x => x.pagePosition == page.pagePosition + 1);
          if((page.name.toLowerCase().includes(this.filterContent.toLowerCase()) || (page2?.name.toLowerCase().includes(this.filterContent.toLowerCase())))){
            pagesSet.push({page1: page, page2});
          }
        }
      }
      let filteredPages = [];
      for(let page of pagesSet){
        filteredPages.push(page.page1);
        filteredPages.push(page.page2);
      }
      let expanded = false;
      if(filteredPages.length > 0){
        expanded = true;
        result.push({child: p.child, groupId: p.groupId, pages: filteredPages, expanded});
      }
    }
    this.displayPages = result;

  }

  public async doMultiEdit(): Promise<void> {
    let pagesSet: { page1: Project, page2: Project }[] = [];

    let pageName1 = '';
    let pageName2 = '';
    let nameDiff = false;
    for (let p of this.pages) {
      for (let id of this.selectedIds) {
        let found = p.pages.find(x => x.id == id);
        if (found != undefined && pagesSet.find(x => x.page1?.id == id || x.page2?.id == id) == undefined) {
          let page2 = p.pages.find(x => x.pagePosition == found.pagePosition + 1);
          pagesSet.push({page1: found, page2});
        }
      }
    }

    for (let p of pagesSet) {
      if ((pageName1 != '' && pageName1 != p.page1?.name) || (pageName2 != '' && pageName2 != p.page2?.name)) {
        nameDiff = true;
      }
      pageName1 = p.page1?.name;
      pageName2 = p.page2?.name;
    }


    if (nameDiff) {

      let message = 'The names of the selected pages do not match and the page content might vary. Once saved, the content of all selected pages will be overridden.';
      let yes = 'Edit anyway';
      let no = 'Abort';
      await this.translate.get('MODALS.PAGE_NAMES_MISSMATCH_WARNING.CONTENT').toPromise()
        .then((res: string) => {
          message = res;
        });
      await this.translate.get('MODALS.PAGE_NAMES_MISSMATCH_WARNING.BUTTON_OK').toPromise()
        .then((res: string) => {
          yes = res;
        });
      await this.translate.get('MODALS.PAGE_NAMES_MISSMATCH_WARNING.BUTTON_CANCEL').toPromise()
        .then((res: string) => {
          no = res;
        });
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: '',
        message: message,
        buttons: [
          {
            text: yes,
            cssClass: 'yes',
            handler: () => {
              this.modalController.dismiss(pagesSet);
            },
          },
          {
            text: no,
            cssClass: 'no',
            handler: () => {
              alert.dismiss();
            },
          },
        ],
      });
      await alert.present();
    } else {
      this.modalController.dismiss(pagesSet);
    }

  }
}
